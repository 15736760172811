import './index.module.scss';
import Layout from '../../../../layout';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';
import ContactForm from '../../../../components/contact-form/contact-form';
import BannerInner from '../../../../components/banner-inner/banner-inner';
import ContentInner from '../../../../components/content-inner/content-inner';
import banner from '../../../../assets/images/services-banner.jpg';

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={banner}
        caption={
          <h2 className="x-large-heading color-white">
            Business Succession Planning
          </h2>
        }
      />
      <ContentInner
        heading={'Business Succession Planning'}
        content={
          <p>
            Running a business can be an all-encompassing enterprise, and all
            too often, business owners are too engrossed in the day to day
            aspects of their business to completely plan for the day when the
            business is to be sold or passed on to another individual. DGM can
            develop a business continuity plan and a business succession plan.
            The continuity plan enables an owner to keep things running while a
            potential buyer is found. It ensures preservation of the current
            business, keeping key employees and valued customers while the buyer
            is found. A business succession plan provides for an orderly and
            stable future transition when the proper time to sell arrives. If
            you or someone you know is thinking of selling their business, the
            first and most critical step is setting a goal to implement both a
            business continuity plan and a business succession plan and DGM can
            help you accomplish these tasks.
          </p>
        }
      />
      <ContactForm />
      <Footer />
    </Layout>
  );
}

export default Index;
